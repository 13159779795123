<template>
  <div class="hk_page_routerview">
    <div class="hk_page_title">
      {{courseName}}
      <div class="hk_page_title_right">
        <el-button type="primary" size="small" @click.stop="seeMidTermFun">期中报告</el-button>&nbsp;
        <!-- <el-dropdown size="small" @command="handleCommand">
          <el-button type="primary" plain size="small">
            批量导入<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="work">导入考勤表</el-dropdown-item>
            <el-dropdown-item command="homework">导入作业表</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      </div>
    </div>
    <div class="hk_page_wrap">
      <div class="hk-common-page">
        <div class="hk_result_total_box">
          共有
          <span class="hk-font-highlight">{{ lessonList.length }}</span> 节课
        </div>

        <el-table :data="lessonList" style="width: 100%">
          <el-table-column label="课节ID" width="100px" fixed>
            <template slot-scope="scope">
              {{ scope.row.eeoClassId }}
            </template>
          </el-table-column>
          <el-table-column label="课程名称" width="200px" fixed>
            <template slot-scope="scope">
              {{ scope.row.className }}
            </template>
          </el-table-column>
          <el-table-column label="课程时间" width="160px">
            <template slot-scope="scope">
              <div>{{ scope.row.beginTime }}</div>
              <div>{{ scope.row.endTime }}</div>
            </template>
          </el-table-column>

          <el-table-column label="时长" width="90px">
            <template slot-scope="scope">
              <span>{{ scope.row.lessonDuration }}</span>
            </template>
          </el-table-column>
          <el-table-column label="授课老师" width="120px">
            <template slot-scope="scope">
              <span>{{ scope.row.teacherName }}</span>
            </template>
          </el-table-column>

          <el-table-column label="课节人数" width="100px">
            <template slot-scope="scope">
              <span class="hk-table-option hk-table-option-first" @click="seeKejieNum(scope.row)">{{ scope.row.classStudentNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="出勤人数" width="100px">
            <template slot-scope="scope">
              <span>{{ scope.row.attendNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="出勤率" width="100px">
            <template slot-scope="scope">
              <span>{{ scope.row.addendRate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="完课人数" width="100px">
            <template slot-scope="scope">
              <span>{{ scope.row.classFinishNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="完课率" width="100px">
            <template slot-scope="scope">
              <span>{{ scope.row.classFinishRate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="作业提交人数" width="120px">
            <template slot-scope="scope">
              <span class="hk-table-option hk-table-option-first" @click="seeHomeworkSubmitNum(scope.row)">{{ scope.row.homeworkFinishNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="作业提交率" width="100px">
            <template slot-scope="scope">
              <span>{{ scope.row.homeworkFinishRate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="课后转入" width="100px">
            <template slot-scope="scope">
              <span class="hk-table-option hk-table-option-first" @click="seeZhuanInNum(scope.row)">{{ scope.row.transferInNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="课后转出" width="100px">
            <template slot-scope="scope">
              <span class="hk-table-option hk-table-option-first" @click="seeZhuanOutNum(scope.row)">{{ scope.row.transferOutNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="插班生">
            <template slot-scope="scope">
              <span class="hk-table-option hk-table-option-first" @click="seeChabanNum(scope.row)">{{ scope.row.callInNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="课后退费" width="100px">
            <template slot-scope="scope">
              <span class="hk-table-option hk-table-option-first" @click="seeRefundNum(scope.row)">{{ scope.row.refundNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="退费率" width="100px">
            <template slot-scope="scope">
              <span>{{ scope.row.refundRate }}</span>
            </template>
          </el-table-column>

          <div slot="empty">
            <p>没有你要找的数据哦！</p>
          </div>
        </el-table>
      </div>
    </div>

    <!-- 导入考勤弹窗 ifWorkDialog -->
    <el-dialog title="导入考勤" :visible.sync="ifWorkDialog" :modal-append-to-body="false" width="480px" @close="hideWorkDialog">
      <div class="export-dialog" v-loaing="pageLoading">
        <div class="export-main">
          <div class="hk_upload_main" v-if="!fileWork">
            <input class="hk_ipt_file" id="J_attent_file" type="file" @change="changeUploadExcel('J_attent_file', 'work')" accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
            <div class="hk_upload_box">
              <div class="hk_upload-tag iconfont icon-jiahao"></div>
              <p class="hk_upload-txt">上传文件</p>
            </div>
          </div>
          <div class="export-remark" v-else>
            <el-form label-width="94px" size="small" :rules="dialogRules" ref="attendForm" :model="dialogWorkForm">
              <el-form-item label="导入备注：" prop="remark">
                <div class="hk-flex">
                  <el-input v-model="dialogWorkForm.remark" placeholder="请输入"></el-input>
                  &nbsp;&nbsp;
                  <el-button type="primary" size="small" @click="exportFun('work', 'attendForm')">导入</el-button>
                </div>
                <el-button type="primary" plain><i class="el-icon-upload el-icon--right"></i>重新上传</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="export-record">
          <h3 class="record-title">导入记录</h3>
          <div class="record-item-list">
            <div class="record-item" v-for="(item,idx) in recordList" :key="idx">{{item.staffName}} {{item.updateTime}}<span class="span">导入表格</span>{{item.remark}}</div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 导入作业弹窗 ifHomeworkDialog -->
    <el-dialog title="导入作业" :visible.sync="ifHomeworkDialog" :modal-append-to-body="false" width="480px" @close="hideHomeworkDialog">
      <div class="export-dialog" v-loaing="pageLoading">
        <div class="export-main">
          <div class="hk_upload_main" v-if="!fileHomework">
            <input class="hk_ipt_file" id="J_homework_file" type="file" @change="changeUploadExcel('J_homework_file','homework')" accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
            <div class="hk_upload_box">
              <div class="hk_upload-tag iconfont icon-jiahao"></div>
              <p class="hk_upload-txt">上传文件</p>
            </div>
          </div>
          <div class="export-remark" v-else>
            <el-form label-width="94px" size="small" :rules="dialogRules" ref="hwForm" :model="dialogHomeWorkForm">
              <el-form-item label="导入备注：" prop="remark">
                <div class="hk-flex">
                  <el-input v-model="dialogHomeWorkForm.remark" placeholder="请输入"></el-input>
                  &nbsp;&nbsp;
                  <el-button type="primary" size="small" @click="exportFun('homework', 'hwForm')">导入</el-button>
                </div>
                <el-button type="primary" plain><i class="el-icon-upload el-icon--right"></i>重新上传</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="export-record">
          <h3 class="record-title">导入记录</h3>
          <div class="record-item-list">
            <div class="record-item" v-for="(item,idx) in recordList" :key="idx">{{item.staffName}} {{item.updateTime}}<span class="span">导入表格</span>{{item.remark}}</div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 课节人数  @close="xxFun" -->
    <el-dialog :visible.sync="ifKejieDialog" :modal-append-to-body="false" width="80%">
      <div slot="title">
        {{kejieTitle}}&nbsp;&nbsp;<span class="hk-font-highlight">课节人数</span>
      </div>
      <div class="kejie-dialog">
        <el-table :data="kejieData" style="width: 100%">
          <el-table-column label="学生姓名" width="100px" fixed="left">
            <template slot-scope="scope">
              {{ scope.row.stuName }}
            </template>
          </el-table-column>
          <el-table-column label="学生手机号" width="120px">
            <template slot-scope="scope">
              {{ scope.row.stuPhone }}
            </template>
          </el-table-column>
          <el-table-column label="辅导老师" width="120px">
            <template slot-scope="scope">
              {{ scope.row.assistantName || '/' }}
            </template>
          </el-table-column>
          <el-table-column label="实际上课时长(分钟)" width="100px">
            <template slot-scope="scope">
              {{ scope.row.attendMin || '/' }}
            </template>
          </el-table-column>
          <el-table-column label="出勤" width="100px">
            <template slot-scope="scope">
              {{ scope.row.attendFlag=='Y'?'出勤':'/' }}
            </template>
          </el-table-column>
          <el-table-column label="迟到" width="100px">
            <template slot-scope="scope">
              {{ scope.row.lateFlag=='Y'?'迟到':'/'  }}
            </template>
          </el-table-column>
          <el-table-column label="辅导客户端回放实际观看覆盖时长(分钟)" width="150px">
            <template slot-scope="scope">
              {{ scope.row.stuPlaybackMin || '/' }}
            </template>
          </el-table-column>
          <el-table-column label="客户端回放视频总时长(分钟)" width="120px">
            <template slot-scope="scope">
              {{ scope.row.videoPlaybackMin || '/' }}
            </template>
          </el-table-column>
          <el-table-column label="麦克风打开时长(分钟)" width="120px">
            <template slot-scope="scope">
              {{ scope.row.micMin || '/' }}
            </template>
          </el-table-column>
          <el-table-column label="上台次数" width="100px">
            <template slot-scope="scope">
              {{ scope.row.upCount || '/' }}
            </template>
          </el-table-column>
          <el-table-column label="上台时长(分钟)" width="100px">
            <template slot-scope="scope">
              {{ scope.row.upMin || '/' }}
            </template>
          </el-table-column>
          <el-table-column label="奖励次数" width="100px">
            <template slot-scope="scope">
              {{ scope.row.awardCount || '/' }}
            </template>
          </el-table-column>
          <el-table-column label="举手次数" width="100px">
            <template slot-scope="scope">
              {{ scope.row.handsUpCount || '/' }}
            </template>
          </el-table-column>
          <el-table-column label="答题器总次数" width="100px">
            <template slot-scope="scope">
              {{ scope.row.answerCount || '/' }}
            </template>
          </el-table-column>
          <el-table-column label="答题正确次数" width="100px">
            <template slot-scope="scope">
              {{ scope.row.answerCorrectCount || '/' }}
            </template>
          </el-table-column>
          <el-table-column label="作业提交" width="100px">
            <template slot-scope="scope">
              {{ scope.row.homeworkStatus || '未提交' }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <!-- 作业提交人数 -->
    <el-dialog :visible.sync="ifHomeworkSubmitDialog" :modal-append-to-body="false" width="80%">
      <div slot="title">
        {{kejieTitle}}&nbsp;&nbsp; <span class="hk-font-highlight">作业提交人数</span>
      </div>
      <div class="kejie-dialog">
        <el-table :data="kejieData" style="width: 100%">
          <el-table-column label="学生姓名" width="100px" fixed="left">
            <template slot-scope="scope">
              {{ scope.row.stuName }}
            </template>
          </el-table-column>
          <el-table-column label="学生手机号" width="120px">
            <template slot-scope="scope">
              {{ scope.row.stuPhone }}
            </template>
          </el-table-column>
          <el-table-column label="辅导老师" width="120px">
            <template slot-scope="scope">
              {{ scope.row.assistantName || '/' }}
            </template>
          </el-table-column>
          <el-table-column label="提交时间" width="155px">
            <template slot-scope="scope">
              {{ scope.row.homeworkFinishTime || '/' }}
            </template>
          </el-table-column>
          <el-table-column label="已批阅" width="100px">
            <template slot-scope="scope">
              {{ scope.row.remarkFlag=='Y'?'已批阅':'/' }}
            </template>
          </el-table-column>
          <el-table-column label="打回订正" width="100px">
            <template slot-scope="scope">
              {{ scope.row.returnFlag=='Y'?'已批阅':'/' }}
            </template>
          </el-table-column>
          <el-table-column label="已订正">
            <template slot-scope="scope">
              {{ scope.row.reviseFlag=='Y'?'已批阅':'/' }}
            </template>
          </el-table-column>
          <el-table-column label="作业分数" width="120px">
            <template slot-scope="scope">
              {{ scope.row.homeworkScore||'/' }}
            </template>
          </el-table-column>
          <el-table-column label="作业得分率" width="120px">
            <template slot-scope="scope">
              {{ scope.row.homeworkRate||'/' }}
            </template>
          </el-table-column>
          <el-table-column label="批阅老师" width="100px">
            <template slot-scope="scope">
              {{ scope.row.remarkBy　|| '/' }}
            </template>
          </el-table-column>
          <el-table-column label="作业评语" width="450px">
            <template slot-scope="scope">
              {{ scope.row.commentInfo ||'/' }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <!-- 课后转入人数 -->
    <el-dialog :visible.sync="ifKehouInDialog" :modal-append-to-body="false" width="80%">
      <div slot="title">
        {{kejieTitle}}&nbsp;&nbsp;<span class="hk-font-highlight">课后转入人数</span>
      </div>
      <div class="kejie-dialog">
        <el-table :data="kejieData" style="width: 100%">
          <el-table-column label="学生姓名" width="100px" fixed="left">
            <template slot-scope="scope">
              {{ scope.row.stuName }}
            </template>
          </el-table-column>
          <el-table-column label="学生手机号" width="120px">
            <template slot-scope="scope">
              {{ scope.row.stuPhone }}
            </template>
          </el-table-column>
          <el-table-column label="辅导老师" width="120px">
            <template slot-scope="scope">
              {{ scope.row.assistantName || '/' }}
            </template>
          </el-table-column>
          <el-table-column label="调整日期" width="100px">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>
          <el-table-column label="原班级">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>
          <el-table-column label="操作人" width="100px">
            <template slot-scope="scope">
              {{ scope.row.creator }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <!-- 课后转出人数 -->
    <el-dialog :visible.sync="ifKehouOutDialog" :modal-append-to-body="false" width="80%">
      <div slot="title">
        {{kejieTitle}}&nbsp;&nbsp;<span class="hk-font-highlight">课后转出人数</span>
      </div>
      <div class="kejie-dialog">
        <el-table :data="kejieData" style="width: 100%">
          <el-table-column label="学生姓名" width="100px" fixed="left">
            <template slot-scope="scope">
              {{ scope.row.stuName }}
            </template>
          </el-table-column>
          <el-table-column label="学生手机号" width="120px">
            <template slot-scope="scope">
              {{ scope.row.stuPhone }}
            </template>
          </el-table-column>
          <el-table-column label="辅导老师" width="120px">
            <template slot-scope="scope">
              {{ scope.row.assistantName || '/' }}
            </template>
          </el-table-column>
          <el-table-column label="调整日期" width="100px">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>
          <el-table-column label="原班级">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>
          <el-table-column label="操作人" width="100px">
            <template slot-scope="scope">
              {{ scope.row.creator }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <!-- 插班生 -->
    <el-dialog :visible.sync="ifChabanDialog" :modal-append-to-body="false" width="80%">
      <div slot="title">
        {{kejieTitle}} &nbsp;&nbsp;<span class="hk-font-highlight">插班生</span>
      </div>
      <div class="kejie-dialog">
        <el-table :data="kejieData" style="width: 100%">
          <el-table-column label="学生姓名" width="100px" fixed="left">
            <template slot-scope="scope">
              {{ scope.row.stuName }}
            </template>
          </el-table-column>
          <el-table-column label="学生手机号" width="120px">
            <template slot-scope="scope">
              {{ scope.row.stuPhone }}
            </template>
          </el-table-column>
          <el-table-column label="辅导老师" width="120px">
            <template slot-scope="scope">
              {{ scope.row.assistantName || '/' }}
            </template>
          </el-table-column>
          <el-table-column label="调整日期" width="100px">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>
          <el-table-column label="原班级">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>
          <el-table-column label="操作人" width="100px">
            <template slot-scope="scope">
              {{ scope.row.creator }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <!-- 课后退费 -->
    <el-dialog :visible.sync="ifRefundDialog" :modal-append-to-body="false" width="80%">
      <div slot="title">
        {{kejieTitle}} &nbsp;&nbsp;<span class="hk-font-highlight">课后退费</span>
      </div>
      <div class="kejie-dialog">
        <el-table :data="kejieData" style="width: 100%">
          <el-table-column label="学生姓名" width="100px" fixed="left">
            <template slot-scope="scope">
              {{ scope.row.stuName }}
            </template>
          </el-table-column>
          <el-table-column label="学生手机号" width="120px">
            <template slot-scope="scope">
              {{ scope.row.stuPhone }}
            </template>
          </el-table-column>
          <el-table-column label="辅导老师" width="120px">
            <template slot-scope="scope">
              {{ scope.row.assistantName || '/' }}
            </template>
          </el-table-column>
          <el-table-column label="退费原因" width="100px">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>
          <el-table-column label="退费详情">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>
          <el-table-column label="操作人" width="100px">
            <template slot-scope="scope">
              {{ scope.row.creator }}
            </template>
          </el-table-column>
          <el-table-column label="登记时间" width="100px">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageLoading: false,
      courseId: '', //query参数 id的值，不是eeoCourseId
      courseName: '', //query参数 name,课程名次
      subjectStr: '', //query参数，学科，英语的话报告的课节名次要换行
      lessonList: [], //所有的课节

      recordList: [], //导入记录
      ifWorkDialog: false, //导入课程的弹窗
      ifHomeworkDialog: false, //导入辅导的弹窗
      fileWork: null,
      fileHomework: null,
      dialogWorkForm: {
        remark: "",
      },
      dialogHomeWorkForm: {
        remark: "",
      },
      dialogRules: {
        remark: [{ required: true, message: "请输入", trigger: "blur" }],
      },

      ifKejieDialog: false,
      kejieData: [], //弹窗中的课节数据
      kejieTitle: '', //课节名次
      ifHomeworkSubmitDialog: false,
      ifKehouInDialog: false,
      ifKehouOutDialog: false,
      ifChabanDialog: false,
      ifRefundDialog: false,
    };
  },
  mounted() {
    let that = this;
    let pageQuery = that.$route.query
    if (pageQuery.id) {
      that.courseId = pageQuery.id
    }
    if (pageQuery.name) {
      that.courseName = decodeURIComponent(pageQuery.name)
    }
    if (pageQuery.subject) {
      that.subjectStr = decodeURIComponent(pageQuery.subject)
    }
    that.getLessonArrFun()
  },
  methods: {
    //期中报告
    seeMidTermFun() {
      this.$router.push('/midterm?id=' + this.courseId + '&name=' + encodeURIComponent(this.courseName) + '&subject=' + encodeURIComponent(this.subjectStr))
    },
    //顶部批量导入
    handleCommand(command) {
      let that = this;
      that.ifWorkDialog = false;
      that.ifHomeworkDialog = false;

      if (command == "work") {//导入考勤
        that.ifWorkDialog = true;
      } else {//导入作业
        that.ifHomeworkDialog = true;
      }
      that.getRecordCourseUpload(command)
    },

    //上传文件,若接口不一样，则需要分开写方法
    changeUploadExcel(eleId, type) {
      let that = this;
      let file = document.getElementById(eleId).files[0];

      if (file) {
        if (type == "work") {//考勤
          that.fileWork = file
        } else {
          that.fileHomework = file
        }
      } else {
        that.$message.error("请先选择文件");
      }
    },

    //导入 先判断备注有没有填写
    exportFun(type, formName) {
      let that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.pageLoading = true;
          let url = '/manage/course/uploadAttendFile'
          let file = null;
          let paramRemark = ''

          if (type == 'work') { //导入考勤
            console.log('出勤')
            file = that.fileWork
            paramRemark = that.dialogWorkForm.remark

          } else { //导入作业
            console.log('作业')
            url = '/manage/course/uploadHomeworkFile'
            file = that.fileHomework
            paramRemark = that.dialogHomeWorkForm.remark
          }
          let fd = new FormData();
          fd.append("file", file);
          fd.append("remark", paramRemark)
          var xhr = new XMLHttpRequest();
          xhr.open("POST", that.interfacePre() + url);

          xhr.setRequestHeader("Authorization", localStorage.getItem('hkmanage_session'));
          xhr.onload = function () {
            let backRes = JSON.parse(xhr.responseText);
            console.log(backRes, '---back xhr')
            if (backRes.code == 200) {
              that.$message({
                message: '导入成功',
                type: 'success'
              });
              //然后要再次获取记录
              that.getRecordCourseUpload(type)
              that.getLessonArrFun()
              setTimeout(() => {
                that.pageLoading = false;
              }, 1000);
            } else {
              that.$message.error('导入失败：' + backRes.message)
              console.log('导入失败。')
              that.pageLoading = false;
            }
          };
          xhr.send(fd);

        } else {
          console.log('未输入')
        }
      });
    },

    //获取 课程文件的上传记录   "EEO_DATA_COURSE"--课程文件 EEO_DATA_CLASS--课节文件
    getRecordCourseUpload(type) {
      let that = this;
      let params = {
        pageNum: 1,
        pageSize: 500
      }
      let ossPath = ''
      if (type == 'work') { //考勤文件
        ossPath = 'EEO_DATA_ATTEND'
      } else { //作业
        ossPath = 'EEO_DATA_HOMEWORK'
      }
      params.ossPath = ossPath

      that.request({
        method: 'post',
        url: '/manage/ossFile/listRecord',
        param: params,
        success: function (res) {
          if (res.code == 200) {
            that.recordList = res.result.list || []

          } else {
            that.$message.error('获取上传记录失败--' + res.msg)
          }
        },
        error: function (error) {
          //服务器异常
          console.log(error);
          that.$message.error('获取上传记录失败');
        }
      })
    },

    hideWorkDialog() {
      this.ifWorkDialog = false;
      this.fileWork = null
      //同时需要给 fileWork =null
    },
    hideHomeworkDialog() {
      this.ifHomeworkDialog = false;
      this.fileHomework = null
      //同时需要给 fileHomework =null
    },

    //获取当前课节的数据弹窗信息 学生的相关数据 
    //dataType ( ALL 所有数据, HOMEWORK 提交作业数据, TRANSFER_IN 课后转入数据, TRANSFER_OUT 课后转出数据,REFUND 退费数据, CALL_IN 插班生数据)
    getClassDialogData(classId, dataType) {
      let that = this;

      that.request({
        method: 'post',
        url: '/manage/class/listClassStudent',
        param: { classId: classId, dataType: dataType },
        success: function (res) {
          if (res.code == 200) {
            that.kejieData = res.result || []

          } else {
            that.kejieData = []
            that.$message.error('获取数据失败--' + res.msg)
          }
        },
        error: function (error) {
          //服务器异常
          console.log(error);
          that.$message.error('获取失败');
        }
      })


    },
    //点击 课节人数
    seeKejieNum(row) {
      // this.$message.info("查看课节人数");
      let that = this;
      that.kejieTitle = row.className
      that.getClassDialogData(row.id, 'ALL')
      that.ifKejieDialog = true;
    },

    //点击 作业提交人数
    seeHomeworkSubmitNum(row) {
      // this.$message.info("作业提交人数");
      let that = this;
      that.kejieTitle = row.className
      that.getClassDialogData(row.id, 'HOMEWORK')
      that.ifHomeworkSubmitDialog = true;
    },

    //点击 课后转入
    seeZhuanInNum(row) {
      // this.$message.info("课后转入");
      let that = this;
      that.getClassDialogData(row.id, 'TRANSFER_IN')
      that.kejieTitle = row.className
      that.ifKehouInDialog = true;
    },

    //点击 课后转出
    seeZhuanOutNum(row) {
      // this.$message.info("课后转出出出");
      let that = this;
      that.getClassDialogData(row.id, 'TRANSFER_OUT')
      that.kejieTitle = row.className
      that.ifKehouOutDialog = true;
    },

    //点击 插班生
    seeChabanNum(row) {
      // this.$message.info("插班生");
      let that = this;
      that.getClassDialogData(row.id, 'CALL_IN')
      that.kejieTitle = row.className
      that.ifChabanDialog = true;
    },
    //点击 课后退费
    seeRefundNum(row) {
      // this.$message.info("课后退费");
      let that = this;
      that.getClassDialogData(row.id, 'REFUND')
      that.kejieTitle = row.className
      that.ifRefundDialog = true;
    },

    //获取课节列表
    getLessonArrFun() {
      let that = this;
      let params = { courseId: that.courseId };

      that.request({
        method: "post",
        url: "/manage/class/listClass",
        param: params,
        success: function (res) {
          if (res.code == 200) {
            that.lessonList = res.result || [];
          } else {
            that.$message.error("获取列表失败--" + res.msg);
          }
        },
        error: function (error) {
          //服务器异常
          console.log(error);
          that.$message.error("获取列表异常");
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.export-dialog {
  .export-main {
    padding-bottom: 20px;
    .export-remark {
      display: flex;
      align-items: center;
      padding-top: 8px;
      .ipt-remark {
        width: 200px;
        margin-right: 6px;
      }
    }
  }
  .export-record {
    .record-title {
      font-weight: 600;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
      padding-bottom: 12px;
    }
    .record-item-list {
      max-height: 450px;
      overflow: auto;
    }
    .record-item {
      font-weight: 400;
      font-size: 14px;
      color: #f08d00;
      line-height: 22px;
      text-align: left;
      .span {
        color: rgba(0, 0, 0, 0.85);
        padding: 0 4px;
      }
    }
  }
}
.kejie-dialog {
  max-height: 480px;
  overflow-y: auto;
}
</style>